import Vue from "vue";
import VueRouter, { RouteRecord } from "vue-router";
import AdminView from "@/views/settings/Jobs.vue";


// Containers
// const DefaultContainer = () => import("@/containers/DefaultContainer.vue");

// Views
// Dashboard
// Item Map View
const Dashboard = () => import("@/views/Dashboard.vue");

// Users
const Users = () => import("@/views/users/Users.vue");
const UserProfile = () => import("@/views/users/Profile.vue");

//Projects
const AllProjects = () => import("@/views/projects/ProjectsList.vue");
const SingleProjectView = () => import("@/views/projects/SingleProject.vue");
const MainProjectView = () => import("@/views/projects/MainProjectView.vue");
const ArchivedProjectsList = () => import("@/views/projects/ArchivedProjectsList.vue");
const Mq3Aq3Pdf = () => import("@/views/projects/Mq3Aq3Pdf.vue");
const Mq3Aq32SWPdf = () => import("@/views/projects/Mq3Aq32SWPdf.vue");
const Mq4Aq42SWDEGESPdf = () => import("@/views/projects/Mq4Aq42SWDEGESPdf.vue");
const Mq3Aq32023Pdf = () => import("@/views/projects/Mq3Aq32023Pdf.vue");
const Mq4Aq42SWPdf = () => import("@/views/projects/Mq4Aq42SWPdf.vue");
const Mq5Aq52SWPdf = () => import("@/views/projects/Mq5Aq52SWPdf.vue");

// Inventory
const Inventory = () => import("@/views/inventory/InventoryList.vue");
const inventoryItem = () => import("@/views/inventory/inventoryItem.vue");
const InventoryArchived = () => import("@/views/inventory/InventoryArchive.vue");

//Roles
const UserRoleList = () => import("@/views/roles/UserRoleList.vue");

//Logs
const userLogs = () => import("@/views/logs/UserLogs.vue");

/*----------  maintenance  ----------*/
const MaintenanceMode = () => import("@/views/settings/MaintenanceMode.vue");
const MaintenanceModeActive = () => import("@/views/maintenance/MaintenanceModeActive.vue");

const Jobs = () => import("@/views/settings/Jobs.vue");
const ItemMap = () => import("@/views/pages/ItemMap.vue");

Vue.use(VueRouter);
const scrollBehavior = function (to: any, from: any, savedPosition: any) {
  if (to.hash) {
    return { selector: to.hash };
  }
  return { x: 0, y: 0 };
};

export default new VueRouter({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior,
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      name: "router.home",
      component: () => import("@/containers/DefaultContainer.vue"),
      meta: { label: "router.home" },
      children: [
        {
          path: "dashboard",
          name: "router.dashboard",
          component: Dashboard,
          meta: {
            label: "router.dashboard",
            requiresLogin: true
          }
        },
        {
          path: "itemMap",
          name: "router.itemMap",
          component: ItemMap,
          meta: {
            label: "router.itemMap",
            requiresLogin: true
          }
        },
        {
          path: "users",
          meta: { label: "router.users", onlyAdmin: true },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "router.users",
              component: Users,
              meta: { onlyAdmin: true }
            }
          ]
        },
        {
          path: "inventory",
          meta: { label: "router.inventory" },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "router.inventory",
              component: Inventory
            },
            {
              path: "archived",
              meta: {
                label: "router.inventoryArchived",
                onlyAdmin: true
              },
              name: "router.inventoryArchived",
              component: InventoryArchived
            },
            {
              path: ":id",
              meta: {
                label: "router.inventorySingleItems"
              },
              name: "router.inventorySingleItems",
              component: inventoryItem,
              props: true
            }
          ]
        },
        {
          path: "roles",
          meta: { label: "router.roles", onlyAdmin: true },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "router.roles",
              component: UserRoleList
            }
          ]
        },
        {
          path: "user",
          meta: { label: "router.profile", maintenanceModeCheck: true },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              component: UserProfile,
              props: true,
              name: "router.profile"
            }
          ]
        },
        {
          path: "projects",
          meta: {
            label: "router.projects",
            requiresLogin: true
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "router.projects",
              component: AllProjects,
              meta: { maintenanceModeCheck: true }
            },
            {
              path: "archived",
              name: "router.archivedProjectsList",
              component: ArchivedProjectsList,
              meta: {
                label: "router.inventoryArchived",
                maintenanceModeCheck: true,
                onlyAdmin: true
              }
            },

            {
              path: "main/:id",
              meta: { label: "router.singleProject", maintenanceModeCheck: true, scrollToTop: true },
              name: "router.singleProject",
              component: MainProjectView,
              props: true
            },
            {
              path: "mq3-aq3-pdf",
              name: "router.mq3Aq3Pdf",
              component: Mq3Aq3Pdf,
              meta: {
                label: "router.Mq3Aq3Pdf",
                maintenanceModeCheck: true,
                onlyAdmin: true
              }
            },
            {
              path: "mq3-aq3-2sw-pdf",
              name: "router.mq3Aq32SWPdf",
              component: Mq3Aq32SWPdf,
              meta: {
                label: "router.Mq3Aq32SWPdf",
                maintenanceModeCheck: true,
                onlyAdmin: true
              }
            },
            {
              path: "mq4-aq4-2sw-deges-pdf",
              name: "router.mq4Aq42SWDEGESPdf",
              component: Mq4Aq42SWDEGESPdf,
              meta: {
                label: "router.mq4Aq42SWDEGESPdf",
                maintenanceModeCheck: true,
                onlyAdmin: true
              }
            },
            {
              path: "mq3-aq3-2023-pdf",
              name: "router.mq3Aq32023Pdf",
              component: Mq3Aq32023Pdf,
              meta: {
                label: "router.mq3Aq32023Pdf",
                maintenanceModeCheck: true,
                onlyAdmin: true
              }
            },
            {
              path: "mq4-aq4-2sw-pdf",
              name: "router.mq4Aq42SWPdf",
              component: Mq4Aq42SWPdf,
              meta: {
                label: "router.mq4Aq42SWPdf",
                maintenanceModeCheck: true,
                onlyAdmin: true
              }
            },
            {
              path: "mq5-aq5-2sw-pdf",
              name: "router.mq5Aq52SWPdf",
              component: Mq5Aq52SWPdf,
              meta: {
                label: "router.mq4Aq42SWPdf",
                maintenanceModeCheck: true,
                onlyAdmin: true
              }
            },
            {
              path: ":id",
              meta: { label: "router.singleProject", maintenanceModeCheck: true },
              name: "router.singleProject",
              component: SingleProjectView,
              props: true
            }
          ]
        },
        {
          path: "logs",
          meta: { label: "router.logs", requiresLogin: true, onlyAdmin: true },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "router.logs",
              component: userLogs,
              props: true
            }
          ]
        },


        {
          path: "maintenance",
          meta: { label: "router.settings", requiresLogin: true,  onlyAdmin: true },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "router.settings",
              component: MaintenanceMode,
              props: true
            },

          ]
        },

        {
          path: "jobs",
          meta: { label: "router.settings", requiresLogin: true,  onlyAdmin: true },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "router.settings",
              component: Jobs,
              props: true
            }
          ]
        },


        // {
        //   path: "system-settings",
        //   name: "router.settings",
        //   component: SettingsView,
        //   meta: { label: "router.settings", onlyAdmin: true }
        // }
        // {
        // 	path: "*",
        // 	redirect: "/dashboard",
        // 	name: "Pages",
        // 	component: {
        // 		render(c) {
        // 			return c("router-view");
        // 		}
        // 	}
        // }
      ]
    },
    {
      path: "/maintenanceActive",
      name: "router.maintenanceActive",
      component: MaintenanceModeActive,
      meta: {
        label: "router.maintenanceActive" ,
        requiresLogin: true}
    }



  ]
});

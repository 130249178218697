import axios from "axios";
import { Device } from "@/interface/DeviceInterface";
import router from "@/router";
axios.defaults.baseURL = process.env.VUE_APP_INVENTORY;

const createAxiosInstance = () => {
  const axiosProjects = axios.create({
    baseURL: process.env.VUE_APP_INVENTORY,
    headers: {
      // @ts-ignore
      Authorization: "Bearer " + router.app.$keycloak.token,
      Accept: "application/json"
      // 'Content-Type': 'application/json'
    }
  });
  return axiosProjects;
};
const DeviceTypes = {
  async allTypes() {
    return await createAxiosInstance().get("api/v1/type");
  }
};
const InventoryStates = {
  async allStates() {
    return await createAxiosInstance().get("/api/v1/inventory/states");
  }
};
const DeviceEndPoint = {
  async getAllDevices() {
    return await createAxiosInstance().get("api/v1/inventory");
  },
  async createNewDevice(data: Device) {
    return await createAxiosInstance().post("api/v1/inventory", data);
  },
  async getAllArchived() {
    return await createAxiosInstance().get(`api/v1/inventory/archive/`);
  },
  async archiveDeviceFromInventory(id: string) {
    return await createAxiosInstance().put(`api/v1/inventory/archive/${id}`);
  },
  async rebootRouterFromInventory(serialNumber: string) {
    return await createAxiosInstance().put(`api/v1/inventory/item/${serialNumber}/reboot`);
  },
  async updateDevice(data: Device) {
    return await createAxiosInstance().put(`api/v1/inventory/${data.id}`, data);
  },
  async updateGpsPositionDevice(serialNumber: string) {
    return await createAxiosInstance().put(`api/v1/inventory/${serialNumber}/callgps`);
  },
  async DeleteArchivedDevice(deviceId: number) {
    return await createAxiosInstance().delete(`api/v1/inventory/archive/${deviceId}/delete`);
  },
  async GetSearchTerm(searchTerm: string) {
    console.log(searchTerm);
    return {
      status: 200,
      data: []
    };
    // return await createAxiosInstance().get(`api/v1/inventory/search/${searchTerm}`);
  },
  async UpdateBatteryList(projectId: String, batteryList: Array<String>) {
    return await createAxiosInstance().put(`api/v1/inventory/updateBatteryProjectId/${projectId}`, batteryList);
  },
  async updateProject(data: any) {
    // Todo: replaced with updateProjectStates

    return await createAxiosInstance().put(`api/v1/inventory/updateProject/${data.id}`, data);
  },

  // Todo: change Name To updateAllDevice
  async changeAllDeviceState() {
    return await createAxiosInstance().put(`api/v1/inventory/changeAllDeviceState/`);
  },
  async deleteAll() {
    return await createAxiosInstance().delete(`api/v1/inventory/delete-all`);
  },
  async updateProjectState(data: any) {
    return await createAxiosInstance().put(`api/v1/inventory/updateProjectState/`, data);
  }
};
const InventoryStatesHealth = {
  async getMetrics() {
    return await createAxiosInstance().get("/api/v1/dashboard/health");
  }
};

export { DeviceTypes, DeviceEndPoint, InventoryStates, InventoryStatesHealth };

import axios from "axios";
import router from "@/router/routerIbotech";

// TypeScript interface
class IbotechService {
  public static userManagerUrl: string | undefined = process.env.VUE_APP_USER_MANAGER;
  public static inventoryUrl: string | undefined = process.env.VUE_APP_INVENTORY;


  // -----------
  // -----------
  // GET Methods
  // -----------
  // -----------
  public async getUserProfile(id: string) {
    const response = await axios(`${IbotechService.userManagerUrl}/user/${id}/profile`, {
      method: "GET",
      headers: IbotechService.getAuthHeaders()
    }).catch((err) => err);

    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async getAllVehicles() {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicles`,
      method: "GET",
      headers: IbotechService.getAuthHeaders()
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async getVehicle(plateNumber: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicle`,
      method: "GET",
      headers: IbotechService.getAuthHeaders(),
      params: {
        plateNumber: plateNumber
      }
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async getAllLocations() {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/locations`,
      method: "GET",
      headers: IbotechService.getAuthHeaders()
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async getMontlyReport(data: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/monthlyReport`,
      method: "GET",
      headers: IbotechService.getAuthHeaders(),
      params: {
        projectId: data.projectId,
        from: data.from,
        to: data.to
      }
    }).catch((err) => err);

    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async getMissingRecords() {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/missingRecords`,
      method: "GET",
      headers: IbotechService.getAuthHeaders(),
    }).catch((err) => err);

    console.log("RESPONSE", response);

    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }



  public async getVehicleRecordsByDate(data: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicleRecordsByDate`,
      method: "GET",
      headers: IbotechService.getAuthHeaders(),
      params: {
        from: data.from,
        to: data.to
      }
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }


  public async getVehicleReport(data: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicleRecordsByVehicle`,
      method: "GET",
      headers: IbotechService.getAuthHeaders(),
      params: {
        plateNumber: data.plateNumber,
        from: data.from,
        to: data.to
      }
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }








  private static getAuthHeaders() {
    return {
      // @ts-ignore
      Authorization: "Bearer " + router.app.$keycloak.token,
      Accept: "application/json"
    };
  }

  // -------------------
  // -------------------
  // PUT OR POST METHODS
  // -------------------
  // -------------------
  public async putOrPostVehicle(data: any, method: 'POST' | 'PUT') {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicle/`,
      method: method,
      headers: IbotechService.getAuthHeaders(),
      data: data
    }).catch((err) => err);
    if (response.status === 202) {
      return response;
    } else {
      return false;
    }
  }

  public async putOrPostLocation(data: any, method: 'POST' | 'PUT') {
    data.locationType = data.locationType.value;

    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/location/`,
      method: method,
      headers: IbotechService.getAuthHeaders(),
      data: data
    }).catch((err) => err);
    if (response.status === 202) {
      return response;
    } else {
      return false;
    }
  }

  public async putVehicleRecord(data: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicleRecord/`,
      method: "PUT",
      headers: IbotechService.getAuthHeaders(),
      params: {
        plateNumber: data.plateNumber,
        projectId: data.id.id,
        notice: data.notice
      }
    }).catch((err) => err);
    if (response.status === 202) {
      return response;
    } else {
      return false;
    }
  }

  public async putVehicleRecordManuel(data: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicleRecordManual/`,
      method: "PUT",
      headers: IbotechService.getAuthHeaders(),
      params: {
        plateNumber: data.plateNumber,
        registrationTime: data.registrationTime,
        projectId: data.projectId,
        notice: data.notice
      }
    }).catch((error) => {

      if(error.response) {

        // 304 is limit of records
        if(error.response.status === 304) {
          return "304";

        // any errors else
        } else {
          return false;
        }
      }
    });

    return response;
  }

  public async postVehicleRecord(data: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicleRecord`,
      method: "POST",
      headers: IbotechService.getAuthHeaders(),
      data: data
    }).catch((err) => err);
    if (response.status === 202) {
      return response;
    } else {
      return false;
    }
  }

  // --------------
  // --------------
  // Delete Methods
  // --------------
  // --------------
  public async deleteVehicle(plateNumber: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicle/`,
      method: "DELETE",
      headers: IbotechService.getAuthHeaders(),
      params: {
        plateNumber: plateNumber
      }
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async deleteLocation(projectId: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/location/`,
      method: "DELETE",
      headers: IbotechService.getAuthHeaders(),
      params: {
        projectId: projectId
      }
    }).catch((err) => err);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  }

  public async deleteRecord(id: any) {
    const response = await axios({
      url: `${IbotechService.inventoryUrl}/api/v1/checkIn/vehicleRecord/`,
      method: "DELETE",
      headers: IbotechService.getAuthHeaders(),
      params: {
        id: id
      }
    }).catch((err) => err);
    if (response.status === 202) {
      return response;
    } else {
      return false;
    }
  }



}

const ibomadeUserService = new IbotechService();
export default ibomadeUserService;
